<template>
  <v-card flat :class="{titlecard: true, first: first, 'rounded-0': true, noborder: noBorder, 'my-3': true }">
    <!-- <v-row no-gutters>
      <v-col><h1>{{ title }}</h1></v-col>
      <v-col class="text-right" v-if="rightText"><h1>{{ rightText }}</h1></v-col>
    </v-row> -->
    <div class="d-flex">
      <h1>{{ title }}</h1>
      <v-spacer></v-spacer>
      <h1 v-if="rightText" class="d-none d-md-block">{{ rightText }}</h1>
    </div>

  </v-card>
</template>

<script>
export default {
  name: "TitleBar",
  //props: ['title', 'first', 'rightText', 'noBorder']
  props: {
    title: String,
    first: {
      type: Boolean,
      default: undefined
    },
    rightText: {
      type: String
    },
    noBorder: {
      type: Boolean,
      default: undefined
    }
  }
}
</script>

<style scoped>
  h1 {
    font-family: "agendalight", Arial, Tahoma, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .titlecard.first {
    border-top: none !important;
  }

  .titlecard.noborder {
    border: none !important;
  }
</style>
