<template>
  <v-app class="fill-height">

    <v-navigation-drawer app right v-model="sideNav" disable-resize-watcher style="z-index: 9999999">
      <v-list class="navlist">
        <v-list-item link v-for="(navItem, idx) in navItems" :key="idx" :to="navItem.route" :exact="navItem.route.exact">
          <v-list-item-title class="text-uppercase text-h6 font-weight-bold">{{ navItem.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <!--<lang-switcher style="flex-direction: row; display: flex; "></lang-switcher>-->
    </v-navigation-drawer>

    <v-container class="pb-0" style="max-width: 1405px;">
      <v-row no-gutters>
        <v-col>
          <!--<v-img :src="require('@/assets/logo-header-mobiel.svg')" max-height="70px" width="100%" contain v-if="$vuetify.breakpoint.mdAndDown"></v-img>-->
          <v-img :src="require('@/assets/header.svg')" width="100%" height="70px"></v-img>
        </v-col>
      </v-row>
      <div class="sticky-placehold" v-show="sticky"></div>
      <v-toolbar color="transparent" flat :class="{sticky, ...navbarClasses, safari: isSafari}" :style="navbarStyles" v-scroll="onScroll" ref="nav" id="navbar" style="position:relative;">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-tabs centered color="black" class="header-tabs d-flex justify-center" style="position:absolute" ref="navtabs" show-arrows>
            <v-menu v-for="(navItem, idx) in navItems" :key="idx" offset-y open-on-hover open-on-focus nudge-left="25" close-delay="50" rounded="0">
              <template v-slot:activator="{ on, attrs }">
                <v-tab v-bind="attrs" v-on="on" :to="navItem.route" :exact="idx === 0">{{$t(navItem.text)}}</v-tab>
              </template>

              <v-list dense v-if="navItem.subitems" class="nav-submenu pa-0">
                <v-list-item style="min-height: 0;" class="pa-0" dense exact v-for="(subroute, subrouteIdx) in navItem.subitems" :key="subrouteIdx">
                  <v-btn :to="{...subroute.route}" :exact="subroute.exact" class="nav-subitem rounded-0 justify-start" min-height="40px" text small block elevation="0">{{ $t(subroute.text) }}</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tabs>
          <!--<lang-switcher style="flex-direction: row; display: flex; position: absolute;z-index: 999" :style="{right: $vuetify.breakpoint.md ? `8%` : `250px`}"></lang-switcher>-->
        </template>
        <template v-else>
          <div style="width: 80%;background-color: white" :style="{marginTop: mobMarginTop}" ref="logo" >
            <v-img :src="require('@/assets/voet_logo-1405x41.svg')" eager height="48px" max-height="48px" position="center left" v-show="sticky"></v-img>
          </div>
          <v-spacer></v-spacer>
            <v-app-bar-nav-icon @click="sideNav = !sideNav"></v-app-bar-nav-icon>
        </template>
      </v-toolbar>
      <v-card class="my-6" flat v-if="!hasSlider"></v-card>
      <slot>
        <div class="fill-height"></div>
      </slot>

      <!-- Footer -->

    </v-container>
    <v-container class="d-flex flex-column mt-auto pb-0" style="max-width: 1405px;">
      <div class="footer">
        <v-row class="pt-16">
          <v-col>
            <v-img :src="require('@/assets/voet_logo-1405x41.svg')" height="40px" width="100%" position="center left"></v-img>
          </v-col>
        </v-row>
        <v-row :no-gutters="$vuetify.breakpoint.xsOnly" class="pt-3 pt-sm-0 pl-2">
          <v-col cols="12" sm="6" class="shrink" style="max-width: 200px">
            <v-row no-gutters><v-col>De Sociëteit</v-col></v-row>
            <v-row no-gutters><v-col>Voorstraat 31</v-col></v-row>
            <v-row no-gutters><v-col>3231 BE Brielle</v-col></v-row>
          </v-col>
          <v-col>
            <v-row no-gutters><v-col>T +31 (0)181 45 95 15</v-col></v-row>
            <v-row no-gutters><v-col>M <a href="mailto:info@framegroep.nl">info@framegroep.nl</a></v-col></v-row>
            <v-row no-gutters><v-col>W <a href="https://www.framevastgoed.nl/">www.framevastgoed.nl</a></v-col></v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-2">
          <v-col><v-card flat dark rounded="0" color="black" width="100%" height="45" class="d-flex px-3" style="align-items: center;">
            <v-row no-gutters>
              <v-col><span>Copyright Frame, Brielle 2022 |
                <a :href="require('@/assets/Disclaimer Frame NL.pdf')" class="white--text" target="_blank">Disclaimer</a> |
                <a :href="require('@/assets/Privacybeleid Frame NL.pdf')" class="white--text" target="_blank">Privacybeleid</a></span></v-col>
              <!--<v-col class="text-right">
                <span style="vertical-align: middle" class="mr-1">Volg ons op</span>
                <a href="https://www.facebook.com/framevastgoed/" class="mr-1" target="_blank"><v-icon>mdi-facebook</v-icon></a>
                <a href="https://twitter.com/framevastgoed" class="mr-1" target="_blank"><v-icon>mdi-twitter</v-icon></a>
                <a href="https://www.linkedin.com/company/18160049/" class="mr-1" target="_blank"><v-icon>mdi-linkedin</v-icon></a>
                <a href="https://www.instagram.com/framevastgoed/" class="mr-1" target="_blank"><v-icon>mdi-instagram</v-icon></a>
              </v-col>-->
            </v-row>
          </v-card></v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>
//import LangSwitcher from '@/components/LangSwitcher';
export default {
  name: "SiteLayout",
  //components: {LangSwitcher},
  data() {
    return {
      sticky: false,
      originalPos: 0,
      sideNav: false,
      navTab: 0,
      mobMarginTop: 0,
      navItems: [
        {text: "home", route: {name: "Home", exact: true}},
        {text: "nieuws", route: {name: "Post", params: { type: "nieuws"}}},
        {text: "projecten", route: {name: "Post", params: { type: "project"}}},
        //{text: "media", route: {name: "Post", params: { type: "blog"}}},
        {text: "contact", route: {name: "Contact"}/*, subitems: [
            //{text: "vacatures", route: {name: "Post", params: { type: "vacature"}}}
          ]*/},
      ]
    }
  },
  props: {
    hasSlider: {
      type: Boolean,
      default: undefined
    }
  },
  methods: {
    onScroll() {
      let rect = this.$refs.nav.$el.getBoundingClientRect();
      if (window.scrollY === 0)
        this.sticky = false;
      if (rect.bottom < 0 && !this.sticky)
        this.sticky = true;
      else if (this.sticky && window.scrollY < this.originalPos)
        this.sticky = false;
    },
    mobileMarginTop() {
      if (!this.$refs.nav || !this.$refs.logo) return 0;
      this.$nextTick(() => {
        let navRect = this.$refs.nav.$el.getBoundingClientRect();
        let logoRect = this.$refs.logo.getBoundingClientRect();
        this.mobMarginTop =  (navRect.height - logoRect.height) + "px";
      })

    }
  },
  watch: {
    sticky(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.navtabs)
        this.$refs.navtabs.onResize();
      this.mobileMarginTop();
    }
  },
  computed: {
    navbarClasses() {
      return this.$vuetify.breakpoint.smAndDown ? {'no-border': false} : {};
    },
    navbarStyles() {
      if (this.sticky && this.$vuetify.breakpoint.mdAndDown)
        return {'border-bottom': '1px solid black !important'};
      return {};
    },
    isSafari() {
      return !(navigator.appVersion.toLowerCase().indexOf('chrome/') > -1);
    },

  },
  mounted() {
    this.originalPos = this.$refs.nav.$el.getBoundingClientRect().top;
  },
  beforeRouteLeave() {
    this.sticky = false;
  }
}
</script>

<style>
  .nav-submenu {
    border: 1px solid black !important;
    border-top: 0 !important;
    background-color: white !important;
  }
  .nav-subitem {
    color: black !important;
    font-size: 16px !important;
    letter-spacing: 5px;
    padding-left: 50px !important;
    padding-right: 50px !important;
    background-color: white !important;
    font-weight: 500 !important;
    font-family: "agendamedium", Arial, Tahoma, sans-serif !important;
  }

  .nav-subitem:before {
    background-color: white !important;
  }
  .nav-subitem:hover:before {
    background-color: gray !important;
  }
  .sticky-placehold {
    height: 48px;
  }

  .sticky-placehold:before {
    content: "";
  }

  .safari .v-toolbar__content {
    padding-bottom: 0 !important;
  }

  .safari .v-tabs-bar {
    margin-top: 1px !important;
  }
</style>
