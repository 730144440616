<template>
  <div>
    <router-view :key="$route.path"></router-view>
  </div>

</template>

<style lang="scss">
@import './scss/app';
</style>

<script>
export default {
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
  mounted() {
  }
};
</script>
